
import Vue from 'vue';

interface Props {
    low: { assetSymbol: string; amount: number; } | null;
    medium: { assetSymbol: string; amount: number; } | null;
    high: { assetSymbol: string; amount: number; } | null;
    asset: string;
    hasError: boolean;
    title: string;
    isEditable?: boolean;
    isFeeNeeded?: boolean;
    disabled?: boolean;
    defaultButton?: 'low' | 'medium' | 'high' | '';
    isWideFeeInput?: boolean;
}

interface Data {
    activeButtonValue: 'low' | 'medium' | 'high' | '';
    feeValue: number | string;
}

interface Methods {
    setActiveButton: (data: 'low' | 'medium' | 'high', firstInit: boolean) => void;
    onInput: (data: any) => void;
}

interface Computed {
    isTransferFree: boolean;
    areFeesSame: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    props: {
        low: {
            type: undefined,
            required: true,
        },
        medium: {
            type: undefined,
            required: true,
        },
        high: {
            type: undefined,
            required: true,
        },
        asset: {
            type: String,
            required: true,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
        isFeeNeeded: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        defaultButton: {
            type: undefined,
            default: '',
        },
        isWideFeeInput: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeButtonValue: '',
            feeValue: 0,
        };
    },
    computed: {
        isTransferFree() {
            if (!this.isFeeNeeded) {
                return true;
            }
            if (!this.low || !this.medium || !this.high) {
                return false;
            }
            return this.low.amount === 0 && this.medium.amount === 0 && this.high.amount === 0;
        },
        areFeesSame() {
            if (!this.low || !this.medium || !this.high) {
                return false;
            }
            return this.low.amount === this.high.amount && this.low.amount === this.medium.amount;
        },
    },
    methods: {
        setActiveButton(value, firstInit = false) {
            if (this.isTransferFree || this.disabled || (this.areFeesSame && !firstInit)) {
                return;
            }
            this.activeButtonValue = value;
            this.feeValue = this[value]?.amount ?? 0;
            this.$emit('select-fee', value);
        },
        onInput(e) {
            const { value } = e.target;
            this.feeValue = value;
            this.$emit('input-fee', value);
        },
    },
    mounted() {
        if (this.defaultButton && !this.isTransferFree) {
            this.setActiveButton(this.defaultButton, true);
        }
        if (this.isTransferFree) {
            this.$emit('select-fee', 'medium');
        }
    },
    watch: {
        isTransferFree(value) {
            if (value) {
                this.$emit('select-fee', 'medium');
            }
        },
        medium() {
            if (this.defaultButton && !this.isTransferFree && this.defaultButton === 'medium') {
                this.setActiveButton(this.defaultButton, true);
            }
        },
        low() {
            if (this.defaultButton && !this.isTransferFree && this.defaultButton === 'low') {
                this.setActiveButton(this.defaultButton, true);
            }
        },
        high() {
            if (this.defaultButton && !this.isTransferFree && this.defaultButton === 'high') {
                this.setActiveButton(this.defaultButton, true);
            }
        },
    },
});
