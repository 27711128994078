
import mixins from 'vue-typed-mixins';

import Blockchain from 'Entities/publicPresenter/Blockchain';
import TransferHistoryData from 'Modules/TransferHistory/TransferHistory.Data.vue';
import Asset from 'Entities/publicPresenter/Asset';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import BlockchainsRequest from 'Entities/publicPresenter/BlockchainsRequest';
import ApiError from 'Entities/ApiError';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import WalletsApi from 'Apis/Wallets';
import UserTransferAddressesParams from 'Entities/privatePresenter/UserTransferAddressesParams';
import WithdrawalFeeRequestData, { IWithdrawalFeeRequestData } from 'Entities/walletExecutor/WithdrawalFeeRequestData';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import router from '@/router';
import WithdrawalRequestData, { IWithdrawalRequestData } from 'Entities/walletExecutor/WithdrawalRequestData';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import { needUpdateTransferHistory } from 'Store/v2/UiActions';
import PublicApi from 'Apis/PublicData';
import BlockchainRequest from 'Entities/publicPresenter/BlockchainRequest';
import BankRequisitesResponseData from 'Entities/walletExecutor/BankRequisitesResponseData';

export interface WithdrawUI {
    currentAssetIndex: number, // asset index
    currentBlockchainIndex: number, // blockchain index
    currentAddressIndex: number,
    currentRequisiteIndex: number,
    asset: string,
    blockchain: string,
    address: null | UserTransferAddress,
    requisite: null | BankRequisitesResponseData,
    fees: null | Record<string, any>,
    amount: number,
    feeSize: null | number,
}

interface IGetFeesPayload {
    accountId: string,
    amount: number,
}

interface Data {
    addresses: UserTransferAddress[];
    requisites: BankRequisitesResponseData[];
    assets: Map<string, Asset>;
    blockchains: Map<string, Blockchain>;
    withdrawUi: WithdrawUI;
    whitelistingData: { assets: Asset[]; blockchains: Blockchain[]; };
}

interface Methods {
    // mutations
    SET_ACTIVE_ADDRESS: (data: number) => void;
    SET_ACTIVE_REQUISITE: (data: number) => void;
    SET_AMOUNT: (data: number) => void;
    SET_FEE_SIZE: (data: number | null) => void;
    SET_ASSETS: (data: Asset[]) => void;
    SET_BLOCKCHAINS: (data: Blockchain[]) => void;
    // actions
    init: () => void;
    getAssets: () => void;
    getBlockchains: () => void;
    getAddresses: () => void;
    getRequisites: () => void;
    getWithdrawFees: (data: IGetFeesPayload) => void;
    doWithdraw: (
        data: string,
        lastRoute?: string,
        fiatDetails?: {
            recipientName: string;
            recipientSwift: string;
            details: string;
            recipientIban: string;
            beneficiaryType: 'person' | 'company';
        }) => void;
    prepareWhitelisting: () => void;
}

interface Computed {
    GET_IS_SELECTED_ASSET_FIAT: boolean;
}

export default mixins(TransferHistoryData).extend<Data, Methods, Computed>({
    mixins: [TransferHistoryData],
    // state
    data() {
        return {
            addresses: [],
            requisites: [],
            assets: new Map<string, Asset>(),
            blockchains: new Map<string, Blockchain>(),
            withdrawUi: {
                currentAssetIndex: 0,
                currentBlockchainIndex: 0,
                currentAddressIndex: 0,
                currentRequisiteIndex: 0,
                asset: '',
                blockchain: '',
                address: null,
                requisite: null,
                fees: null,
                amount: 0,
                feeSize: null,
            },
            whitelistingData: {
                assets: [],
                blockchains: [],
            },
        };
    },
    // getters
    computed: {
        GET_IS_SELECTED_ASSET_FIAT() {
            return this.assets.get(this.withdrawUi.asset)?.type === 'fiat';
        },
    },
    // mutations and actions
    methods: {
        // mutations
        SET_ACTIVE_ADDRESS(addressIndex: number) {
            this.withdrawUi.currentAddressIndex = addressIndex;
            this.withdrawUi.address = this.addresses[addressIndex];
        },
        SET_ACTIVE_REQUISITE(requisiteIndex: number) {
            this.withdrawUi.currentRequisiteIndex = requisiteIndex;
            this.withdrawUi.requisite = this.requisites[requisiteIndex];
        },
        SET_AMOUNT(amount: number) {
            this.withdrawUi.amount = amount;
        },
        SET_FEE_SIZE(size: number | null) {
            this.withdrawUi.feeSize = size;
        },
        SET_ASSETS(assets) {
            const map = new Map<string, Asset>();
            assets.forEach((a) => {
                map.set(a.symbol, a);
            });
            this.assets = map;
        },
        SET_BLOCKCHAINS(blockchains) {
            const map = new Map<string, Blockchain>();
            blockchains.forEach((b) => {
                map.set(b.name, b);
            });
            this.blockchains = map;
        },
        // actions
        async init() {
            await this.getAssets();
            await this.getBlockchains();
            await this.getAddresses();
        },
        async getAssets() {
            try {
                const { data: assets } = await PublicApi.publicGetAssets(new AssetsRequest({
                    page: 1,
                    perPage: 256,
                    transferable: true,
                    includeTransferDetails: true,
                    fromPlacementName: 'Single Broker',
                }));
                const filteredAssets = assets.filter(({ transferDetails }) => transferDetails?.some(({ destination }) => !destination));
                this.withdrawUi.asset = filteredAssets[0].symbol;
                this.SET_ASSETS(filteredAssets);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting assets');
                }
            }
        },
        async getRequisites() {
            try {
                const { data: requisites } = await WalletsApi.listUserBankRequisites();
                this.requisites = requisites;
                if (requisites.length) {
                    [this.withdrawUi.requisite] = requisites;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting bank requisites');
                }
            }
        },
        async getBlockchains() {
            try {
                const blockchainsSet = new Set<string>();
                const details = this.assets.get(this.withdrawUi.asset).transferDetails;
                details!.forEach((d) => {
                    if (d.source === 'Single Broker' && !d.destination) {
                        blockchainsSet.add(d.blockchainName);
                    }
                });
                const blockchains: Blockchain[] = [];
                for (let i = 0; i < blockchainsSet.size; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: blockchain } = await PublicApi.publicGetBlockchain(new BlockchainRequest({
                        name: Array.from(blockchainsSet)[i],
                    }));
                    blockchains.push(blockchain);
                }
                this.withdrawUi.blockchain = blockchains[0].name;
                this.SET_BLOCKCHAINS(blockchains);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting blockchains');
                }
            }
        },
        async getAddresses() {
            try {
                const { data: addresses } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
                    blockchainName: this.withdrawUi.blockchain,
                    status: 'APPROVED',
                }));
                this.addresses = addresses.filter(({ assetSymbols }) => assetSymbols.includes(this.withdrawUi.asset));
                if (addresses.length) {
                    [this.withdrawUi.address] = addresses;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting addresses');
                }
            }
        },
        async getWithdrawFees(data: IGetFeesPayload) {
            let requestParams = {
                accountId: data.accountId,
                amount: data.amount,
                assetSymbol: this.withdrawUi.asset,
                fiatTransferDetails: this.withdrawUi.requisite?.alias ? {
                    alias: this.withdrawUi.requisite.alias,
                } : undefined,
            } as IWithdrawalFeeRequestData;
            if (this.withdrawUi.address?.memo) {
                requestParams = {
                    ...requestParams,
                    address: this.withdrawUi.address?.address,
                    memo: this.withdrawUi.address?.memo,
                    blockchainName: this.withdrawUi.blockchain,
                };
            } else {
                requestParams = {
                    ...requestParams,
                    address: this.withdrawUi.address?.address,
                    blockchainName: this.withdrawUi.blockchain,
                };
            }
            try {
                const { data: res } = await WalletsApi.getWithdrawalFees(new WithdrawalFeeRequestData(requestParams));
                const feeAsset = Object.keys(res.low!)[0];
                const fees = {
                    low: {
                        amount: res.low![feeAsset],
                        assetSymbol: feeAsset.toUpperCase(),
                    },
                    medium: {
                        amount: res.medium![feeAsset],
                        assetSymbol: feeAsset.toUpperCase(),
                    },
                    high: {
                        amount: res.high![feeAsset],
                        assetSymbol: feeAsset.toUpperCase(),
                    },
                };
                this.withdrawUi.fees = fees;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting fees', { root: true });
                }
            }
        },
        async doWithdraw(accountId: string, lastRoute = '/wallets', fiatDetails) {
            this.$store.state.Auth.isVerificationInProgress = true;
            this.$store.state.Auth.lastRoute = lastRoute;
            const { emailCode, totpCode } = await this.$store.dispatch('Auth/getMFAToken', { type: MFA_ENROLL_FACTOR_TYPES.EMAIL_TOTP, action: 'Withdraw' }, { root: true });
            this.$store.state.Auth.isVerificationInProgress = false;
            let requestParams = {} as IWithdrawalRequestData;
            if (fiatDetails) {
                requestParams = {
                    accountId,
                    assetSymbol: this.withdrawUi.asset,
                    blockchainName: this.withdrawUi.blockchain,
                    amount: this.withdrawUi.amount,
                    fee: Number(this.withdrawUi.fees.medium.amount),
                    totp: totpCode,
                    emailCode,
                    fiatTransferDetails: {
                        alias: this.withdrawUi.requisite.alias ?? '',
                    },
                };
            } else if (this.withdrawUi.address!.memo) {
                requestParams = {
                    accountId,
                    assetSymbol: this.withdrawUi.asset,
                    blockchainName: this.withdrawUi.blockchain,
                    amount: this.withdrawUi.amount,
                    address: this.withdrawUi.address!.address,
                    fee: Number(this.withdrawUi.feeSize),
                    totp: totpCode,
                    emailCode,
                    memo: this.withdrawUi.address!.memo,
                };
            } else {
                requestParams = {
                    accountId,
                    assetSymbol: this.withdrawUi.asset,
                    blockchainName: this.withdrawUi.blockchain,
                    amount: this.withdrawUi.amount,
                    address: this.withdrawUi.address!.address,
                    fee: Number(this.withdrawUi.feeSize),
                    totp: totpCode,
                    emailCode,
                };
            }
            try {
                this.$store.commit(SET_LOADING_ON(undefined), { root: true });
                const { data: res } = await WalletsApi.withdraw(new WithdrawalRequestData(requestParams));
                const { data: transfer } = await WalletsApi.privateGetTransfer(new TransferRequest({
                    id: res.transferId,
                }));
                this.UPDATE_TRANSFER(transfer.serialize());
                await router.push(lastRoute);
                await this.$store.dispatch('Notificator/showSuccessNotification', `Withdraw has been ${transfer.status}`);
            } catch (error) {
                this.$store.commit(SET_LOADING_OFF(undefined), { root: true });
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during performing withdraw', { root: true });
                }
                await this.$router.push(lastRoute);
            } finally {
                await this.$store.dispatch(needUpdateTransferHistory(undefined), { root: true });
                this.$store.commit(SET_LOADING_OFF(undefined), { root: true });
            }
        },
        async prepareWhitelisting() {
            const { data: assets } = await PublicApi.publicGetAssets(new AssetsRequest({
                transferable: true,
                includeTransferDetails: true,
                perPage: 256,
                fromPlacementName: 'Single Broker',
            }));
            const { data: blockchains } = await PublicApi.publicGetBlockchains(new BlockchainsRequest({
                perPage: 256,
                placementName: 'Single Broker',
            }));
            this.whitelistingData.assets = assets;
            this.whitelistingData.blockchains = blockchains;
        },
    },
    async mounted() {
        await this.$store.dispatch('VuexEventListener/addActionListener', {
            type: 'UiActions/needUpdateAddresses',
            callback: async () => {
                await this.getAddresses();
            },
        });
    },
});
